import { Fragment } from 'react';
import { CheckIcon, DeviceMobileIcon } from '@heroicons/react/outline';
import Logo from './Logo';
import holdingImage from './holding-image-lawn.jpg';

const services = [
  'Car park cleaning',
  'Commercial cleaning',
  'Commercial garden maintenance',
  'Commercial grounds maintenance',
  'Commercial pressure washing',
  'Driveway cleaning',
  'Garden clearance',
  'Garden maintenance',
  'Garden makeovers',
  'Grounds maintenance',
  'Hedge cutting',
  'Hedging',
  'lawn care',
  'lawn mowing',
  'Patio cleaning',
  'Planting',
  'Pruning',
  'Rockeries',
  'Site clearance',
  'Town centre cleaning',
];

function App() {
  return (
    <Fragment>
      <div className='relative py-4 lg:py-16 bg-white'>
        <div
          className='hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block'
          aria-hidden='true'
        />
        <div className='max-w-7xl mx-auto bg-gradient-to-r from-rose-600 to-rose-800 lg:bg-none lg:px-8'>
          <div className='lg:grid lg:grid-cols-12'>
            <div className='relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent'>
              <div
                className='absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden'
                aria-hidden='true'
              />
              <div className='max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0'>
                <div className='aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1'>
                  <img
                    className='object-cover object-right rounded-3xl shadow-2xl'
                    src={holdingImage}
                    alt=''
                  />
                  {/* <div className='absolute inset-0 rounded-3xl bg-gradient-to-r from-rose-100 to-rose-100 mix-blend-multiply' /> */}
                </div>
              </div>
            </div>

            <div className='relative bg-gradient-to-r from-rose-600 to-rose-800 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center'>
              <div
                className='hidden absolute inset-0 overflow-hidden rounded-3xl lg:block'
                aria-hidden='true'
              >
                <svg
                  className='absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0'
                  width={404}
                  height={384}
                  fill='none'
                  viewBox='0 0 404 384'
                  aria-hidden='true'
                >
                  <defs>
                    <pattern
                      id='64e643ad-2176-4f86-b3d7-f2c5da3b6a6d'
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits='userSpaceOnUse'
                    >
                      <circle
                        cx={2}
                        cy={2}
                        r={2}
                        fill='currentColor'
                        className='text-rose-400'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
                  />
                </svg>
                <svg
                  className='absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2'
                  width={404}
                  height={384}
                  fill='none'
                  viewBox='0 0 404 384'
                  aria-hidden='true'
                >
                  <rect
                    width={404}
                    height={384}
                    fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
                  />
                </svg>
              </div>
              <div className='relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6 text-center xl:text-left'>
                <div className='mb-10 lg:-ml-14'>
                  <Logo colour="fill-white" />
                </div>
                <h2
                  className='text-3xl font-extrabold text-white lg:-ml-14'
                  id='join-heading'
                >
                  <span className='text-5xl'>🌱</span> Our new website is
                  growing
                </h2>
                <p className='text-lg text-white xl:w-2/3'>
                  ...but don't hesitate to get in touch for a quote or
                  information about any of our services.
                </p>
                <div>
                  <h3 className="text-xl font-semibold text-white mb-4">Call on:</h3>
                  <div className='inline-flex items-center p-3 gap-2 rounded-md bg-rose-500 text-white'>
                    <DeviceMobileIcon className='h-6 w-6' aria-hidden='true' />
                    <a
                      href='tel:07919595922:'
                      className='text-lg tracking-wide  font-base lg:cursor-auto lg:pointer-events-none'
                    >
                      07919 595922
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-50 overflow-hidden pb-8'>
        <div className='relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8'>
          <svg
            className='absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4'
            width={404}
            height={784}
            fill='none'
            viewBox='0 0 404 784'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='8b1b5f72-e944-4457-af67-0c6d15a99f38'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <circle
                  cx={2}
                  cy={2}
                  r={2}
                  fill='currentColor'
                  className='text-rose-300 lg:text-rose-600'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill='url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)'
            />
          </svg>

          <div className='relative lg:grid lg:grid-cols-3 lg:gap-x-8'>
            <div className='lg:col-span-1'>
              <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Professional garden & grounds maintenance.
              </h2>
            </div>
            <div className='mt-12 lg:mt-0 lg:col-span-2'>
              <dl className='space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8'>
                {services.map((service) => (
                  <div key={service} className='relative'>
                    <dt>
                      <CheckIcon
                        className='absolute h-6 w-6 text-green-500'
                        aria-hidden='true'
                      />
                      <p className='ml-9 text-lg leading-6 font-medium text-gray-900'>
                        {service}
                      </p>
                    </dt>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-rose-700 py-10">
        <div className="w-[20rem] mx-auto">
          <Logo colour="fill-white" />
        </div>
      </div>
    </Fragment>
  );
}

export default App;
